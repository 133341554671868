import React, { useState, useContext, useEffect } from 'react';
import Master from "../../utils/Master";
import certificateminipng from './cert123.png';
import Cicons from './../Cicons/Cicons';
import './certificatemini.css';



const Certificatemini = (props) => {

    const {
        candidatename,
        issuedate,
        certificatetitle,
        certificatesubtitle,
        certificatedesc,
        program,
        desccoursename,
        certificateiconsshow
    } = useContext(Master);



// useEffect(()=>{
    
//     document.getElementsByClassName("cdesccription")[0].innerHTML=certificatedesc.replace("$$",`<span class='replacewithgroupname'>`+desccoursename+`</span>`);


// },[certificatedesc,desccoursename])

    return (
        <div className='certificatemini'>


        <div className='certificateminicontent-wrapper'>
            <div className='content'>


            <div className='body'>
                
                <div className='certificateimge'>
                <img src={certificateminipng}/>
                </div>
                
                {/* <div className='certificatecontent'>

                <div className='ctitle'>{certificatetitle}</div>
                <div className='csubtitle'>{certificatesubtitle}</div>
                <div className='cposttitle'>This certificate is proudly awarded to</div>
                <div className='cname'>{candidatename}</div> */}


                {/* <div className='hrbelowname'>
                <hr/>
                </div> */}

                {/* <div className='cdesccription'>
                {certificatedesc}
                </div>
                <div className='cdate'>Issue date: &nbsp;  {issuedate}</div>

             { ((issuedate!="") && (program!="")) &&  <div className='cid'>Certificate ID:&nbsp;{program[0]+issuedate?.substring(2,4)+12345}</div> }
             {certificateiconsshow &&
                <Cicons class={'certificationminicicons'}/>
                } */}

            {/* </div> */}


            </div>
            </div>

            </div>

        </div>

    );
};

export default Certificatemini;