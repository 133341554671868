import React, { useState, useContext, useEffect } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import Master from "../../utils/Master";
import { useNavigate } from 'react-router-dom';
import './createandgen.css'
import 'reactjs-popup/dist/index.css';
import Textgen from '../../elements/textgen/Textgen';
import Text from '../../elements/text/Text';
import * as constants from '../../constants/Constants';
import Check from '../../elements/check/Check';
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Label from '../../elements/Label/Label';
import Radio from '../../elements/radio/Radio';
import Select from 'react-select'
import Certificatemini from '../certificatemini/Certificatemini';
import Studentupload from '../studentupload/Studentupload';
import Csvdata from '../csvdata/Csvdata';
import Button from '../../elements/button/Button';
import Textarea from '../../elements/textarea/Textarea';
import Textareagen from '../../elements/textarea/Textareagen';
import Rte from '../Rte/Rte';
import Rtecourse from '../Rte/Rtecourse';
import Cicons from '../Cicons/Cicons';



const Createandgen = (props) => {
    const navigate = useNavigate();
    const {
        selectedmenu,
        setselectedmenu,
        certificatetitle,
        setcertificatetitle,
        certificatedesc,
        setcertificatedesc,
        issuedate,
        setissuedate,
        setprogram,
        program,
        grouplist,
        setgrouplist,
        selectedgroupobject,
        setselectedgroupobject,
        certificatesubtitle,
        setcertificatesubtitle,
        csvfile,
        saveandcontinue,
        setsaveandcontinue,
        showcsvdata,
        candidatename,
        setcandidatename,
        secertificateicons,
        desccoursename,
        setdesccoursename,
        emailhtml,
        setemailhtml,
        certificateiconsshow,
        setcertificateiconsshow,
        certificatepostsubtitle,
        setcertificatepostsubtitle,
        aboutcourse,
        setaboutcourse,
        clear

    } = useContext(Master);


    const [dateValue, setdateValue] = useState();
    const [certificatedate,setcertificatedate]=useState("");

    useEffect(() => {
        // setselectedgroupobject({label:"",value:0})
        setcertificatetitle("CERTIFICATE");
        setcertificatesubtitle("Of Completion");
        setcertificatepostsubtitle("THIS CERTIFICATE IS PRESENTED TO");
        setcertificatedesc("For successfully completing the training in $$ at Gradious Technologies (P) Ltd.")
        // setdesccoursename("Javascript Fullstack Developer");
        setselectedmenu(selectedmenu);
        setcertificateiconsshow(false);
        if (certificatedesc.length == "") {
            document.getElementById('gen-coursedesc-input').style.display = 'none';
            document.getElementById("gen-coursedesc-display").innerHTML = "Enter description: use $$ in place of course name";

        } else {
            document.getElementById("gen-coursedesc-display").innerHTML = certificatedesc.replace("$$", `<span class='replacewithgroupname'>` + desccoursename + `</span>`);

        }



        axios
            .get(process.env.REACT_APP_API_BASE + `selectgroup/`)
            .then((response) => {
                setgrouplist(response.data.result);
                document.getElementById('gen-coursedesc-input').style.display = 'none';

            })
            .catch((error) => {
                console.error('API call error:', error);
            });
    }, [])

console.log("---   ", typeof selectedgroupobject =='undefined');
// console.log("---   ",selectedgroupobject.hasOwnProperty("label"));

    const handleUploadUserfile = () => {
        const form = new FormData();
        // if()

        form.append("csv", csvfile);
        form.append("groupid", (typeof selectedgroupobject =='undefined')? null:selectedgroupobject.value);
        form.append("groupname", (typeof selectedgroupobject =='undefined')?null: selectedgroupobject.label);
        form.append("program", program.value);
        form.append('desccoursename', desccoursename);
        form.append("title", certificatetitle);
        form.append("subtitle", certificatesubtitle);
        form.append("postsubtitle", certificatepostsubtitle);
        form.append("description", certificatedesc);
        form.append("issuedate", dateValue);
        form.append("emailhtml", emailhtml);
        form.append("showicons", certificateiconsshow == false ? 0 : 1);
        form.append("about", aboutcourse);

        axios
            .post(process.env.REACT_APP_API_BASE + "generate", form, {
                mode: "no-cors",
                headers: {
                    "Content-type": "multipart/form-data",
                },
            })
            .then((response) => {
                // alert("Certificated generated Successfully!")
                let myColor = { background: '#0E1717', text: "#FFFFFF" };
                notify.show(' Certificates Generated!', 'success', 5000, myColor);
                clear();
            })
            .catch((error) => {
                let myColor = { background: '#FF0000', text: "#FFFFFF" };
                notify.show('Email Server Error, Click Add and Issue Certificate Again! ', 'error', 5000, myColor);

            })
    };

    const onDateChange = (event) => {

        var day = new Date(event.target.value).getDate();
        var mon = new Date(event.target.value).getMonth();
        mon=mon+1;
        if((mon+"").length==1){
            mon="0"+mon;
        }
        if((day+"").length==1){
            day="0"+day;
        }
        var year = new Date(event.target.value).getFullYear();
        var finaldate=day+"/"+mon+"/"+year;
        setcertificatedate(finaldate);
        setdateValue(event.target.value);
        setissuedate(event.target.value);

      
    };


    useEffect(() => {
        if (certificatedesc.length == "") {

            document.getElementById("gen-coursedesc-display").innerHTML = "Enter description: use $$ in place of course name";
        } else {
            document.getElementById("gen-coursedesc-display").innerHTML = certificatedesc.replace("$$", `<span class='replacewithgroupname'>` + desccoursename + `</span>`);
        }
    }, [certificatedesc, desccoursename])



    function handlesaveandcontinue(value) {
        if (
            (certificatetitle == "") || (certificatedesc == "") || (certificatesubtitle == "") ||
            /* (program == "") || */ (issuedate == "") || (candidatename == "") 
            // || (selectedgroupobject?.value == "")
            || (program?.value == "") || (aboutcourse == "")

        ) {
            let myColor = { background: '#FF0000', text: "#FFFFFF" };
            notify.show('Enter all fields!', 'error', 5000, myColor);

        } else {
            setsaveandcontinue(value)
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 500);
        }

    }

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_BASE + `selectedgroupicons/` + selectedgroupobject?.value)
            .then((response) => {
                secertificateicons(response.data.result[0].iconlist)
            })
            .catch((error) => {
                console.log('API call error:', error);
            });

    }, [selectedgroupobject])

    function changeshowcertificateicons() {
        setcertificateiconsshow(!certificateiconsshow);
    }

    const options = [
        { value: "COE", label: "COE" },
        { value: "Workshop", label: "Workshop" },
        { value: "Bootcamp", label: "Bootcamp" },
        { value: "Internal Trainee", label: "Internal Trainee" }
    ];

    function descriptiveinputfocus() {
        document.getElementById('gen-coursedesc-display').style.display = 'none';
        document.getElementById('gen-coursedesc-input').style.display = 'block';

    }

    function descriptiveinputblur() {
        document.getElementById('gen-coursedesc-display').style.display = 'block';
        document.getElementById('gen-coursedesc-input').style.display = 'none';
    }


    return (
        <>
            <Notifications />
            <div className='createandgen'>
                <div className='details'>

                    <div className='gen-coursesummary'>

                        <div className='gen-left-coursename'>

                            {desccoursename + "  Course"}

                        </div>
                        <div className='gen-left-completedby'>
                            {"Completed by  " + candidatename}

                        </div>
                        <div className='gen-left-issuedon'>

                            <div className='gen-left-issuedon-label'>
                                Issued on:&nbsp;
                            </div>
                            <div className='gen-left-issuedon-value'>
                                {certificatedate}
                            </div>

                        </div>
                        <div className='gen-left-certificateid'>
                            <div className='gen-left-certificateid-label'>
                                Certificate ID:&nbsp;
                            </div>
                            {(program == "") &&
                                <div className='gen-left-certificateid-value'>
                                    {"select program and date"}
                                </div>
                            }
                            {(program != "") &&
                                <div className='gen-left-certificateid-value'>
                                    {program > 0 ? "select program" : program.value[0] + issuedate?.substring(2, 4) + 12345}
                                </div>
                            }
                        </div>

                    </div>
                    <div className='course-description-label'>  <h5>Course Description:</h5></div>
                    <div className='course-description-rte'>
                        <Rtecourse />
                    </div>

                </div>


                <div className='gen-certificatemini-wrapper'>
                    <Certificatemini />

                    <div className='gen-title'>
                        <Textgen
                            label={"Certificate title"}
                            update={setcertificatetitle}
                            value={certificatetitle}
                            required={true}
                            name={"certificatetitle"}
                            regx={constants.regx.alphabits.name}
                            errortext={"Enter only alphabits"}
                        />
                    </div>

                    <div className='gen-subtitle'>
                        <Textgen
                            label={"Certificate subtitle"}
                            update={setcertificatesubtitle}
                            value={certificatesubtitle}
                            required={true}
                            name={"certificatesubtitle"}
                            regx={constants.regx.alphabits.name}
                            errortext={"Enter only alphabits"}
                        />
                    </div>

                    <div className='gen-postsubtitle'>
                        <Textgen
                            label={"Certificate postsubtitle"}
                            update={setcertificatepostsubtitle}
                            value={certificatepostsubtitle}
                            required={true}
                            name={"certificatesubtitle"}
                            regx={constants.regx.alphabits.name}
                            errortext={"Enter only alphabits"}
                        />
                    </div>
                    <div className='gen-candidatename'>
                        <Textgen
                            label={"Candidate Name"}
                            update={setcandidatename}
                            value={candidatename}
                            required={true}
                            name={"candidatename"}
                            regx={constants.regx.alphabits.name}
                            errortext={"Enter only alphabits"}
                        />
                    </div>

                    <div className='gen-coursedesc'
                        onMouseEnter={() => { descriptiveinputfocus(); }} onMouseLeave={() => { descriptiveinputblur(); }}
                    >
                        <div id="gen-coursedesc-input" >
                            <Textareagen
                                placeholder={"Enter description: use $$ in place of course name"}
                                update={setcertificatedesc}
                                value={certificatedesc}
                                required={true}
                                name={"course desc"}
                                label={"Enter description: use $$ in place of course name"}
                                errortext={"Enter only alphabits"}

                            />
                        </div>
                        <div id='gen-coursedesc-display' onFocus={() => { descriptiveinputblur(); }} onMouseEnter={() => { descriptiveinputfocus(); }} >

                        </div>
                    </div>

                    <div className='gen-issuedate'>{"Issued on: "+certificatedate}</div>

                    {((issuedate != "") && (program != "")) && <div className='gen-certificateid'>Certificate ID:&nbsp;{program.value[0] + issuedate?.substring(2, 4) + 12345}</div>}
                    {certificateiconsshow &&
                        <Cicons class={'certificationminicicons'} />
                    }
                    <div className='course-details-label'>  <h5>Course Details:</h5></div>
                    <div className='gen-additional-inputs'>
                        <div className='gen-additional-inputs-row'>
                          
                        
                            <div className='gen-left-coursename'>
                                <Textgen
                                    label={"Enter course name"}
                                    update={setdesccoursename}
                                    value={desccoursename}
                                    required={true}
                                    name={"candidatename"}
                                    regx={constants.regx.alphabits.name}
                                    errortext={"Enter only alphabits"}
                                />
                            </div>

                            <div className='gen-left-issuedon'>

                                {/* <Label label={"Issued on:"} /> */}
                                <div className='thelabel'><h6>Issueed Date: &nbsp;</h6></div>
                                <div className='gen-issuedate-input'>
                                <input
                                    type="date"
                                    value={dateValue}
                                    onChange={onDateChange}
                                    placeholder="dd-mm-yyyy"
                                />
                                </div>
                            </div>
                            <div className='gen-show-icons-on-certificate'>
                                <Check label={'Show Icons'} checked={certificateiconsshow} value={'Yes'} name={'  Show Icons'} onchange={changeshowcertificateicons} />
                                <div className='thelabel'><h6>Show Icons: &nbsp;</h6></div>

                            </div>
                        </div>
                        <div className='gen-additional-inputs-row'>

                            <div className='certificateid-selectbox'>

                                <div className='thelabel'><h6>Select Program: &nbsp;</h6></div>
                                {/* <select onChange={(e) => setprogram(e.target.value)}>
                                {options.map((option, index) => {
                                    return (
                                        <option key={index}>
                                            {option}
                                        </option>
                                    );
                                })}
                            </select> */}
                                <Select
                                    value={program}
                                    onChange={setprogram}
                                    options={constants.Programs}
                                />

                            </div>
                            <div className='groupselect-section'>
                                <div className='thelabel'><h6>Select Group: &nbsp;</h6></div>
                                <div className='selectbox'>
                                    <Select
                                        value={selectedgroupobject}
                                        onChange={setselectedgroupobject}
                                        options={grouplist}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    {!saveandcontinue &&
                        <Button label={"Save & Continue"} onclick={() => handlesaveandcontinue(true)} classname={"saveandcontinue"} />
                    }
                </div>
            </div>

            {saveandcontinue && <>

                <div className='gen-student-upload-section'>
                <>
                    <hr />
                </>


                    <h3>{"Upload Student list via Csv with (Name,Email,Phone No) format"}</h3>
                    <Studentupload />
                

            
            {/* } */}

            <>
                <hr />
            </>

            {showcsvdata && <>
                <div className='csv-file-data-section'>
                    <Csvdata />
                </div>
                <div className='email-placeholder'>
                    Placeholders $na$ for candidate name, $cn$ for course name , $ce$ for certificate link and $li$ for linkedin link.
                </div>

                <div className='email-rte'>

                    <Rte />
                </div>

                <div className='addcertificatediv'>
                    <Button onclick={() => handleUploadUserfile()} classname={"addandissuecertificate"} disabled={false} label={"Add and Issue Certificate"} />
                </div>
            </>
            }
            </div>
            </>
            
}
            
        </>
    )

}

export default Createandgen;
