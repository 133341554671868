import React, { useState } from 'react';
import './textgen.css';



const Textgen = (props) => {
    let [haserror, sethaserror] = useState(false);
    let [inedit, setinedit] = useState(false);
    let [ipboxclass,setipboxclass]=useState("ipgenbox");

    function handlefocus(e) {
        // sethaserror(false);
        setipboxclass('ipgenboxedit');
    }
    function handlefocusout(e) {
        validation(e,'focusout');
    }

    function handlechange(e) {
        props.update(e)
        if(props.regx!=undefined || props.regex!=null){
            validation(e,'change');
        }


    }

    function validation(inputtxt,from) {
            var regx = props.regx;
            if(inputtxt==""){
                setipboxclass('ipgenbox')
            }
            else{
            if(inputtxt.match(regx)) {
                if(from=='focusout'){
                    
                    setipboxclass  ('ipgenboxok')
                }
                if(from=='change'){
                    setipboxclass('ipgenboxedit')
                }

                // sethaserror(false);
                // ipboxclass('ipboxedit')
            }  
            else {  
            //   sethaserror(true);
            setipboxclass('ipgenboxerror')
            }
        }
    }


    return (
        <>
            {/* <div className={"itext"}> */}
            {/* <div className={"itext"}> */}
                {/* <div className={'blockdiv'}> */}
                    <input
                        type="text"
                        className={ipboxclass}
                        onFocus={(e)=>handlefocus(e)}
                        onBlur={(e)=>handlefocusout(e.target.value)}
                        value={props.value}
                        required={props.required}
                        name={props.name}
                        onChange={(e)=>handlechange(e.target.value)}
                        // onSelect={(e)=>handlefocus(e)}
                        placeholder={props.label}
                    />
                    {/* <span className="iplabel">{props.label}</span> */}
                {/* </div> */}
            

            {/* {haserror &&
                <div className={'errortxt'}>{props.errortext}</div>
            } */}

{/* </div> */}





        </>
    )

}

export default Textgen;
