import React, { useState,useContext, useEffect } from 'react';
import RichTextEditor from 'react-rte';
import Master from "../../utils/Master";


const Rte = (props) => {

    const {
        setemailhtml,
        emailhtml,
        desccoursename
    } = useContext(Master);


    const [value, setValue] = useState(RichTextEditor.createEmptyValue());

    const onChange = (value) => {
      setValue(value);


    var hello=`<p>hello how are you Yes?</p> Yes im good<p>`;



      setemailhtml(value.toString('html'));

      if (props.onChange) {
        props.onChange(value.toString('html'));
      }
    };
    // 

    useEffect(()=>{

if(value.toString('html')=='<p><br></p>')
{
     
    var tmpvalue='<p>Dear $na$, </p>'+
  '<p>Heartiest Congratulations on successfully completing $cn$ training with Gradious Technologies (P) Ltd. We appreciate your commitment and believe this training will equip you with the essential skills to excel in your software career. We are sure, with continuous dedication you will scale greater heights.</p>'+
  '<p>As a token of appreciation of your hard work, we are issuing a certificate of completion. Please find the certificate using the link below.</p>'+
    '<p> $ce$ </p>'+
  '<p>By the way, let your professional network know about your newly acquired skills. Share this certification on your LinkedIn profile by clicking on the LinkedIn Link provided below and tag @Gradious. We\'ll be happy to reshare with our followers and bring you more glory.</p>'+
    '<p> $li$ </p>'+
  '<p>We wish you continued success in all your future endeavors! We are always available for a quick chat and would love to hear your success stories.</p>'+
  '<div>'+
'Warm Regards,<br>'+
'Gradious HR Team<br>'+
'Gradious Technologies<br>'+
'<img src="https://gradious.com/wp-content/uploads/2021/09/Final-Logo-2.svg" height="50" width="50">'+
'<div>';
  setemailhtml(tmpvalue);
  tmpvalue=tmpvalue.replace('$cn$',desccoursename);

  setValue(RichTextEditor.createValueFromString(tmpvalue, 'html'))
 
}

    },[value,desccoursename])



    useEffect(()=>{

        var hello=`hello\n how are you \n Yes`;


    },[])

  
    return <RichTextEditor value={value} onChange={onChange} />;
  };
export default Rte;