import React, { useState, useContext, useEffect } from 'react';
import Notifications, {notify} from 'react-notify-toast';
import Master from "../../utils/Master";
import Text from '../../elements/text/Text';
import Button from '../../elements/button/Button';
import axios from "axios";
import * as constants from '../../constants/Constants'
import Iconslist from '../Iconslist/Iconslist';
import './icongroup.css';



const IconGroup = (props) => {
    const {
        groupname,
        setgroupname,
        groupicons,
        setgroupicons,
        apicalled,
        setapicalled
    } = useContext(Master);

    useEffect(() => {


        axios
            .get(process.env.REACT_APP_API_BASE+`group`)
            .then((response) => {
                // seticonlist(response.data.result)
            })
            .catch((error) => {
                console.error('API call error:', error);
            });
            
            return () => {
                setgroupname("");

                setgroupicons([]);

              };
    }, [apicalled,])


    function savegroup(){

        if ((groupname == null) || (groupicons == undefined) || (groupname.length==0)|| (groupicons.length==0) ) {

            let myColor = { background: '#FF0000', text: "#FFFFFF" };
            if(groupname.length==0){
                notify.show('Enter a name for the group!', 'error', 5000, myColor);
            }
            if(groupicons.length==0){
                notify.show('Please Select Icons!', 'error', 5000, myColor);
            }
     

        }else{

            axios
            .post(process.env.REACT_APP_API_BASE+`group`,
            JSON.stringify({
                groupname:groupname,
                iconlist:groupicons.toString(),
              }),
            {
                headers: {
                  "Content-type": "application/json",
                },
              }
        )
            .then((response) => {
              if (response.data.success) {
              }
            let myColor = { background: '#0E1717', text: "#FFFFFF" };
            notify.show('Group Created!', 'success', 5000, myColor);
            setgroupname("");
            setgroupicons([]);
            setapicalled(!apicalled)
            })
            .catch((error) => {
              console.error('API call error:', error);
            });
        }
    }

    return (

        <div className='icongroupadd'>
             <Notifications />
            <div className='namesection'>
                <h3>Add new group</h3>
            <div className='name'>
                <Text
                    label={"group Name"}
                    update={setgroupname}
                    value={groupname}
                    required={true}
                    name={"groupname"}
                    regx={constants.regx.alphabits.name}
                    errortext={"Enter only alphabits"}
                />
                    </div>

                    <br/>
                <div className='uploadbutton'>                    
                <Button  onclick={savegroup} label={"Save"} classname={"addbutton"} /* disabled={ (groupname.length==0)|| (groupicons.length==0) } */ />
            </div>
<br/> <br/>
            </div>
            <div className='iconselection'>
            <h3>Select icons for the group {groupicons.length}/10 </h3>
            <Iconslist selected={true}  deselected={true} groupicons={groupicons} all={false} edit={false} />
            </div>
        </div>
    );
};

export default IconGroup;