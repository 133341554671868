import React, { useState, useContext, useEffect } from 'react';
import Master from "../../utils/Master";
import './Fileupload.css';



const Fileupload = (props) => {
  const {
    csvfile,setcsvfile
} = useContext(Master);


    const showFile = async (e) => {
      setcsvfile( e.target.files[0])
      var filepath= e.target.value;
      var filename=filepath.substring(filepath.lastIndexOf("\\")+1);
      props.setfilename(filename);
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
          const text = (e.target.result);
          props.setfiledata(text);
          props.setfiledatalines(text.split("\n"));
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 500);

        };
        reader.readAsText(e.target.files[0]);
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
      }, 500);
      }

    return (
        <>
            <div className={"ispan"}>

            <div id='studentuploadbutton'className={props.classname}>
          <label for='file' style={{position: 'absolute', width: 120, height: 25, cursor: 'pointer'}}>{props.label}</label>
          <input id='file' type='file'  onChange={(e) => showFile(e)} style={{opacity: 0}} />
        </div>                
        
         </div>

        </>
    )

}

export default Fileupload;
