import Container from 'react-bootstrap/Container';
import { Routes, Route, useNavigate, useLocation,BrowserRouter,Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css'
import gradlogo from '../../assets/images/gradlogo.svg';


function Header() {

  return (
    <Navbar className="bg-body-tertiary">

      <Container>
        <Navbar.Brand href="new">
          <img
            alt=""
            src={gradlogo}
            width="100"
            height="50"
            className="d-inline-block align-top"
          />{' '}
          {/* React Bootstrap */}
        </Navbar.Brand>
    

      <Container className="justify-content-end" id="header-nav-links">


        {/* <Nav.Link className="justify-content-end"> */}
          <NavDropdown title="Certificate"/*  id="basic-nav-dropdown" */ >
            <NavDropdown.Item >
            <Link
              id="issued"
              to="/issued"
              className='navbar-link-item'
            >
              Issued
            </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
            <Link
              id="new"
              to="/new"
              className='navbar-link-item'
            >
              New
            </Link>
            </NavDropdown.Item>

          </NavDropdown>

          <NavDropdown title="Icons">
            <NavDropdown.Item>
            <Link
              id="icongroups"
              to="/icongroups"
              className='navbar-link-item'
            >
              Icon Group
            </Link>
            </NavDropdown.Item>

            <NavDropdown.Item>
            <Link
              id="addicons"
              to="/addicons"
              className='navbar-link-item'
            >
              Add Icon
            </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
            <Link
              id="addicongroups"
              to="/addicongroups"
              className='navbar-link-item'
            >
              Add Icon Group
            </Link>
            </NavDropdown.Item>

          </NavDropdown>
        {/* </Nav.Link> */}
      </Container>
      
      </Container>
    </Navbar>
  );

}

export default Header;