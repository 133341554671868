import React, { useState, useContext, useEffect } from 'react';
import { Textfit } from 'react-textfit';
import Notifications, { notify } from 'react-notify-toast';
import useFitText from "use-fit-text";
import Master from "./../../utils/Master";
import axios from "axios";
import certificatepng from './../../assets/images/cert123.png';
import gradlogo from '../../assets/images/gradlogo.svg';
import linkedin from './../../assets/images/linked.svg';
import dicon from './../../assets/images/dicon.svg';
import Cicons from './../Cicons/Cicons';
import './certificate.css';
import html2canvas from 'html2canvas';
import ReactLoading from 'react-loading';
import {Helmet} from "react-helmet";
import Resizer from "react-image-file-resizer";
import certgradlogo from './../../assets/images/pngcertgradlogo.svg';


const Certificate = (props) => {

    const {
        candidatename,
        setcandidatename,
        setgroupname,
        setgroupdesc,
        setgroupicons,
        issuedate,
        setissuedate,
        certificateid,
        setcertificateid,
        secertificateicons,
        setcertificatedesc,
        certificatedesc,
        certificatetitle,
        setcertificatetitle,
        certificatesubtitle,
        setcertificatesubtitle,
        setdesccoursename,
        desccoursename,
        certificateiconsshow,
        setcertificateiconsshow,
        certificatepostsubtitle,
        setcertificatepostsubtitle,
        aboutcourse,
        setaboutcourse
    } = useContext(Master);

    const [theurl, settheurl] = useState(window.location.href);
    const [theyear, settheyear] = useState("");
    const [encurl, setencurl] = useState("");
    const [certificateimg, setcertificateimg] = useState();
    const [showload, setshowload] = useState(true);
    const [certificatedate,setcertificatedate]=useState("");
    const [themetadescription,setthemetadescription]=useState("");
    const [resized, setResized] = useState(false);
    const [thedescription,setthedescription]=useState([]);
    const [minicertificateimg, setminicertificateimg] = useState();
    const [certificatefound, setcertificatefound]=useState(true);

    
const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        250,
        250,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });


    useEffect(() => {

        setshowload(true);
        settheurl(window.location.href);

        var loc = window.location.href;
        var url = loc.split('/');
        var theid = url[url.length - 2]

        // setshowload(true);
        document.body.style.overflow = 'hidden';

        axios
            .get(process.env.REACT_APP_API_BASE + "certificate/" + theid)
            .then((response) => {
                if(response.data.result.length>0){   
                    setcertificatefound(true);
                console.log("the response object :::   ", response);
                document.getElementsByClassName('certificate')[0].style.filter = "blur(100px)";
                // document.getElementsByClassName("cdesccription")[0].innerHTML = response.data.result[0].description.replace("$$", "<span class='replacewithgroupname' style='font-weight:800'>" + response.data.result[0].coursename + '</span>'+"");
                
                setthedescription(response.data.result[0].description.split("$$"));




                var issuedate = response.data.result[0].issued;
                var day = new Date(issuedate).getDate();
                var mon = new Date(issuedate).getMonth();
                mon=mon+1;
                if((mon+"").length==1){
                    mon="0"+mon;
                }
                if((day+"").length==1){
                    day="0"+day;
                }

                var year = new Date(issuedate).getFullYear();
                var finaldate=day+"/"+mon+"/"+year;

                setcertificatedate(finaldate);
                setcandidatename(response.data.result[0].cname);
                setgroupdesc(response.data.result[0].description);
                setgroupicons(response.data.result[0].iconlist);
                setissuedate((response.data.result[0].issued + "").substring(0, 10));
                setcertificateid(response.data.result[0].id);
                secertificateicons(response.data.result[0].iconlist);
                setgroupname(response.data.result[0].groupname);
                setcertificatedesc(response.data.result[0].description.replace("$$", response.data.result[0].coursename));
                setcertificatetitle(response.data.result[0].title);
                setdesccoursename(response.data.result[0].coursename);
                setcertificateiconsshow(response.data.result[0].showicons)
                setcertificatesubtitle(response.data.result[0].subtitle);
                setcertificatepostsubtitle(response.data.result[0].postsubtitle);
                setaboutcourse(response.data.result[0].about);
                var metadesc=response.data.result[0].description.replace("$$",response.data.result[0].coursename);

                    document.title = (response.data.result[0].postsubtitle + " " + response.data.result[0].cname);

                    var metaDescription = document.querySelector('meta[name="description"]');
                    if (!metaDescription) {
                        metaDescription = document.createElement('meta');
                        metaDescription.setAttribute('name', 'description');
                        document.head.appendChild(metaDescription);
                        metaDescription.setAttribute('content', metadesc);

                    }

                    
                    var metaurl = document.querySelector('meta[name="url"]');
                    if (!metaurl) {
                        metaurl = document.createElement('meta');
                        metaurl.setAttribute('name', 'url');
                        document.head.appendChild(metaurl);
                        metaurl.setAttribute('content', window.location.href);

                    }


                    var metatype = document.querySelector('meta[name="type"]');
                    if (!metatype) {
                        metatype = document.createElement('meta');
                        metatype.setAttribute('name', 'type');
                        document.head.appendChild(metatype);
                        metatype.setAttribute('content', "website");

                    }

                    var metatitle = document.querySelector('meta[name="title"]');
                    if (!metatitle) {
                        metatitle = document.createElement('meta');
                        metatitle.setAttribute('name', 'title');
                        document.head.appendChild(metatitle);
                        metatitle.setAttribute('content', response.data.result[0].postsubtitle + " " + response.data.result[0].cname);



                    }


                    var metaimage = document.querySelector('meta[name="image"]');
                    if (!metaimage) {
                        metaimage = document.createElement('meta');
                        metaimage.setAttribute('name', 'image');
                        document.head.appendChild(metaimage);
                        metaimage.setAttribute('content', certificatepng);

                    }

                    var metaurl = document.querySelector('meta[name="og:url"]');
                    if (!metaurl) {
                        metaurl = document.createElement('meta');
                        metaurl.setAttribute('name', 'og:url');
                        document.head.appendChild(metaurl);
                        metaurl.setAttribute('content', window.location.href);

                    }


                    var metatype = document.querySelector('meta[name="og:type"]');
                    if (!metatype) {
                        metatype = document.createElement('meta');
                        metatype.setAttribute('name', 'og:type');
                        document.head.appendChild(metatype);
                        metatype.setAttribute('content', "website");

                    }

                    var metatitle = document.querySelector('meta[name="og:title"]');
                    if (!metatitle) {
                        metatitle = document.createElement('meta');
                        metatitle.setAttribute('name', 'og:title');
                        document.head.appendChild(metatitle);
                        metatitle.setAttribute('content', response.data.result[0].postsubtitle + " " + response.data.result[0].cname);



                    }


                    var metaDescription = document.querySelector('meta[name="og:description"]');
                    if (!metaDescription) {
                        metaDescription = document.createElement('meta');
                        metaDescription.setAttribute('name', 'og:description');
                        document.head.appendChild(metaDescription);
                        metaDescription.setAttribute('content', metadesc);

                    }

                    var metaimage = document.querySelector('meta[name="og:image"]');
                    if (!metaimage) {
                        metaimage = document.createElement('meta');
                        metaimage.setAttribute('name', 'og:image');
                        document.head.appendChild(metaimage);
                        metaimage.setAttribute('content', certificatepng);

                    }



                    var metacard = document.querySelector('meta[name="twitter:card"]');
                    if (!metacard) {
                        metacard = document.createElement('meta');
                        metacard.setAttribute('name', 'twitter:card');
                        document.head.appendChild(metacard);
                        metacard.setAttribute('content', "summary_large_image");

                    }


                    var metaurl = document.querySelector('meta[name="twitter:url"]');
                    if (!metaurl) {
                        metaurl = document.createElement('meta');
                        metaurl.setAttribute('name', 'twitter:url');
                        document.head.appendChild(metaurl);
                        metaurl.setAttribute('content', window.location.href);

                    }

                    var metadomain = document.querySelector('meta[name="twitter:domain"]');
                    if (!metadomain) {
                        metadomain = document.createElement('meta');
                        metadomain.setAttribute('name', 'twitter:domain');
                        document.head.appendChild(metadomain);
                        metadomain.setAttribute('content', "certificate.gradious.com");

                    }


                    var metatype = document.querySelector('meta[name="twitter:type"]');
                    if (!metatype) {
                        metatype = document.createElement('meta');
                        metatype.setAttribute('name', 'twitter:type');
                        document.head.appendChild(metatype);
                        metatype.setAttribute('content', "website");

                    }

                    var metatitle = document.querySelector('meta[name="twitter:title"]');
                    if (!metatitle) {
                        metatitle = document.createElement('meta');
                        metatitle.setAttribute('name', 'twitter:title');
                        document.head.appendChild(metatitle);
                        metatitle.setAttribute('content', response.data.result[0].postsubtitle + " " + response.data.result[0].cname);



                    }


                    var metaDescription = document.querySelector('meta[name="twitter:description"]');
                    if (!metaDescription) {
                        metaDescription = document.createElement('meta');
                        metaDescription.setAttribute('name', 'twitter:description');
                        document.head.appendChild(metaDescription);
                        metaDescription.setAttribute('content', metadesc);

                    }

                    var metaimage = document.querySelector('meta[name="twitter:image"]');
                    if (!metaimage) {
                        metaimage = document.createElement('meta');
                        metaimage.setAttribute('name', 'twitter:image');
                        document.head.appendChild(metaimage);
                        metaimage.setAttribute('content', certificatepng);

                    }




                // document.querySelector('meta[property="og:title"]').setAttribute("content", response.data.result[0].postsubtitle+" "+response.data.result[0].cname);
                // document.querySelector('meta[property="og:description"]').setAttribute("content", metadesc);
                // document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);

                // // document.querySelector('meta[property="og:image"]').setAttribute("content", './../../assets/images/cert123.png');

                // document.querySelector('meta[property="site_name"]').setAttribute("content", "Gradious Technologies");
                // document.querySelector('meta[property="title"]').setAttribute("content", response.data.result[0].postsubtitle+" "+response.data.result[0].cname);
                // document.querySelector('meta[property="description"]').setAttribute("content", metadesc);
                // document.querySelector('meta[property="url"]').setAttribute("content", window.location.href);
                // document.querySelector('meta[property="image"]').setAttribute("content", certificatepng);

                //////////////////////////////////////////////////////////////////////////////////////




// <title>Certificate</title>
// <meta name="description" content="Certificate issued">

// <!-- Facebook Meta Tags -->
// <meta property="og:url" content="https://www.certificate.gradious.com/certificate/W2412358/">
// <meta property="og:type" content="website">
// <meta property="og:title" content="Certificate">
// <meta property="og:description" content="Certificate issued">
// <meta property="og:image" content="https://certificate.gradious.comcert123.png">

// <!-- Twitter Meta Tags -->
// <meta name="twitter:card" content="summary_large_image">
// <meta property="twitter:domain" content="certificate.gradious.com">
// <meta property="twitter:url" content="https://www.certificate.gradious.com/certificate/W2412358/">
// <meta name="twitter:title" content="Certificate">
// <meta name="twitter:description" content="Certificate issued">
// <meta name="twitter:image" content="https://certificate.gradious.comcert123.png">





//                 ///////////////////////////////////////////////////////////////////////////////////////////////




                var issuemonth = (issuedate + "").substring(7, 5);

                setthemetadescription(response.data.result[0].description.replace("$$",response.data.result[0].coursename ))


                var buildurl = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=` + response.data.result[0].coursename + `&organizationId=74117225&issueYear=` + (response.data.result[0].issued + "").substring(0, 4) + `&issueMonth=` + issuemonth + `&certId=` + response.data.result[0].id + `&certUrl=` + window.location.href + ``;

                setencurl(buildurl);
                const aboutcoursehtml = document.getElementById('certificate-about-course');
                aboutcoursehtml.innerHTML=response.data.result[0].about;

                const element = document.getElementById('ttwrapper');
                
                setTimeout(() => {
                    html2canvas(element, {
                        width: 2000,
                        height: 1414
                    }).then(function (canvas) {
                        var cerimg = canvas.toDataURL('image/jpg');
                        setcertificateimg(cerimg);
                        element.style.display = 'none';  
                        canvas.toBlob(function(blob){
                            makeminiimage(blob);
                            // document.querySelector('meta[property="og:image"]').setAttribute("content", blob);

                            setshowload(false);
                          },'image/png');

                        setTimeout(() => {
                            let thebody = document.getElementsByClassName('certificatecontent-wrapper')[0];
                            thebody.style.display='none';

                           let thepic= document.getElementsByClassName('canvasimage-wrapper')[0];
                            
                            thepic.style.display='none';
                            document.getElementsByClassName('certificate')[0].style.filter = "none";
                            // canvas.toBlob(function(blob){
                            //     makeminiimage(blob);
                            //     setshowload(false);
                            //   },'image/png');
                            
                            // document.body.style.overflow = 'visible';

                        }, 1000);

                    })

}, 1000)

            }else{
                setcertificatefound(false);

                let myColor = { background: '#FF0000', text: "#FFFFFF" };
                notify.show('Invalid Certificate!', 'error', 15000, myColor);
            }

            })
            .catch((error) => {
                console.error('API call error:', error);
            });

    }, [])


    async function makeminiimage(canvas){
        const image = await resizeFile(canvas);
        setminicertificateimg(image);
    }

    const handleDownloadImage = () => {

        const element = document.getElementById('the-final-image');
        let link = document.createElement('a');
        link.href = certificateimg;
        link.download = 'Certificate-'+certificateid+'.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };


    return (
        <>
        

{showload && <div className='the-spinner'> <ReactLoading type={"spin"} color={"#000000"} height={'20%'} width={'20%'} /> </div>
}

{certificatefound &&
        <div className='certificate'>

            <div className='header-section'>
            <div className='header-inner'>

                <a class="same-logo" href="https://gradious.com/">
                    <img class="" src={certgradlogo} sizes="0px" alt="Gradious" />
                </a>

            </div>
            </div>
            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className='cert-createandgen'>


                <div className='details'>

                    <div className='certgen-coursesummary'>
                        <div className='certgen-coursesummary-legends'>
                        <div className='certgen-left-cnames'>
                        <div className='certgen-left-coursename'>
                            {/* <p> */}
                            {desccoursename + "  Course"}
                            {/* </p> */}
                        </div>
                        <div className='certgen-left-completedby'>
                            {"Completed by  " + candidatename}

                        </div>
                        </div>
                        <div className='certgen-left-issuedids'>
                        <div className='certgen-left-issuedon'>

                            <div className='certgen-left-issuedon-label'>
                                {"Issued on: "}&nbsp;
                            </div>
                            <div className='certgen-left-issuedon-value'>
                                {" "+certificatedate}
                            </div>

                        </div>
                        <div className='certgen-left-certificateid'>
                            <div className='certgen-left-certificateid-label'>
                                {"Certificate ID: "}&nbsp;
                            </div>

                                <div className='certgen-left-certificateid-value'>
                                    {" "+certificateid}
                                </div>
                        </div>
</div>

 
                        </div>

                        <div className='certificate-util-buttons'>
                        <div className='linkedinshare'>
                           <div className='linkedinshare-buttondiv'><a href={encurl} target="_blank"><img src={linkedin} /></a></div> 
                          <div  className='certificate-download-button' onClick={handleDownloadImage}> <img src={dicon} /> </div> 
                        </div>
                        {/* <div className='download-certificate-button' onclick={handleDownloadImage}>
                            <image src={dicon}/>
                           

                        </div> */}
                    </div>

                    </div>
                    {/* {aboutcourse} */}
                    <div className='certificate-about-course-enroll' >
                    <div className='certificate-about-course' id="certificate-about-course">
                      
                    </div >
                    {/* <div className='certificate-enroll-button-section'>
                        <button className='enroll-now-button'>Enroll Now</button>
                        </div> */}
                    </div>
                </div>


                <div className='certificatemini-wrapper'>

                <div className='canvasimage' id="canvas-image-download">
                    <img src={certificateimg} id="the-final-image" />
                </div>
                </div>
            </div>
            <hr></hr>
            <div className='the-footer'>
            Copyright Gradious Technologies (P) Ltd - 2024
            </div>

            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className='certificatecontent-wrapper'>


                <div className='content'>

                    <div id="ttwrapper">

                        <div className='body' >

                            <div className='certificateimge' id="certificateimageid">
                                <img src={certificatepng} />
                                <hr />
                            </div>
                            <div className='certificatecontent'>
                                <div className='ctitle'>{certificatetitle}</div>
                                <div className='csubtitle'>{certificatesubtitle}</div>
                                <div className='cposttitle'>{certificatepostsubtitle}</div>

                                <div className='cname'>

<Textfit
          mode={"single"}
          max ={140}
          min={70}
          forceSingleModeWidth={(candidatename.length>20)?false:true   }
          // simulating a resizing event to make the page update
          onMouseLeave={() => setResized((state) => !state)}
          style={{

            width: "100%",
            minHeight: "70px",
            maxHeight:"140px",
            position:"absolute",
            display:'flex',
            justifyContent: "space-around",
            marginTop:(candidatename.length>25)?"50px":candidatename.length>20?"35px":"-10px"
          }}
        >
          {candidatename}
        </Textfit>

                                </div>

                                <div className='cdesccription'>
                                    <span className='thedescription'>{thedescription[0]}</span>
                                     <span className='replacewithgroupname'>{" "+desccoursename+" "}</span>
                                    <span className='thedescription'>{thedescription[1]}</span>
                                </div>
                                <div className='cdate'> {"Issued on: "+certificatedate}</div>
                                <div className='cid'>{"Certificate ID: "+certificateid}</div>
                                {certificateiconsshow &&
                                <Cicons class={'cicons'} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='canvasimage-wrapper' id="canvas-image-download-wrapper">

                <div className='canvasimage' id="canvas-image-download">
                    <img src={certificateimg} id="the-final-image" />
                </div>

            </div>

        </div>

        }

        {!certificatefound &&            <Notifications />
 }
        </>
    );
};

export default Certificate;