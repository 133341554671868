import React, { useState, useContext, useEffect } from 'react';
import './Csvdata.css';
import Master from "../../utils/Master";
import Fileupload from '../../elements/fileupload/Fileupload';



const Csvdata = (props) => {


    const {
        csvfiledata,
        setcsvfiledata,
        csvfiledatainlines,
        setcsvfiledatainlines,
        csvfilename,
        setcsvfilename,
        buildstudentdata,
        csvfiledatainarray,
        setcsvfiledatainarray
    } = useContext(Master);

const [errortext,seterrortext]=useState("");

function makestudentdata(){
    setcsvfiledatainarray(buildstudentdata(csvfiledatainlines))
}

function modifythecsvdata(thedata){
    setcsvfiledatainlines(thedata);
    setcsvfiledatainarray(buildstudentdata(thedata));


}

    return (
 <div className='csvdata'>
    <Fileupload label={"Modify"}  setfilename={setcsvfilename} setfiledatalines={modifythecsvdata} setfiledata={setcsvfiledata}/>

<table className='table'>
    <thead className='headsection'>
          <tr className='head'>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
          </tr>
          </thead>
          <tbody className='body'>
          {csvfiledatainarray.map((item, i) => (
            <tr key={i} className={1%2==0?'even':'odd'}> 
              <td>{item[0]}</td>
              <td>{item[1]}</td>
              <td>{item[2]}</td>
              {/* <td>{item.issued.substring(0,10)}</td> */}
            </tr>
          ))}
        </tbody>
      </table>


    {/* <Button onclick={()=>makestudentdata()} classname={"csvuploadvutton"} disabled={false} label={"Add and Issue Certificate"}/>  */}



        </div>


    );
};

export default Csvdata;