import React, { useState, useContext, useEffect } from 'react';
import axios from "axios";
import './Certificatesissued.css';
import Text from '../../elements/text/Text';



const Certificatesissued = (props) => {


  const [allcertificates, setallcertificates] = useState([]);

  const [uploaderror, setuploaderror] = useState();
  const [searchresults, setsearchresults] = useState("");
  const [searchterm, setsearchterm] = useState('');



  function performsearch(value) {
    setsearchterm(value);
    if (searchterm == "") {
      setsearchresults(allcertificates);
    } else {
      var tempsearchresults = JSON.parse(JSON.stringify(allcertificates));
      tempsearchresults = tempsearchresults.filter((candidate) => ((candidate.cname.toLowerCase().includes(value)) || (candidate.id.toLowerCase().includes(value))))
      setsearchresults(tempsearchresults);
    }

  }


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE+`certificates`)
      .then((response) => {
        setsearchresults(response.data.result);
        setallcertificates(response.data.result);

      })
      .catch((error) => {
        console.error('API call error:', error);
      });
  }, [])


  return (

    <div className='Certificatesissued'>


      <div className='searchbynameorid'>
        <Text
          label={"Search by Name or Id"}
          update={performsearch}
          value={searchterm}
          required={true}
          name={"iconame"}
        />
      </div>
      <div class="table-container">
      <table className='tablemain'>
        <thead className='headsection'>
          <tr className='head'>
            <th>Name</th>
            <th>certificate ID</th>
            {/* <th>Learning Path</th> */}
            <th>Date of Issue</th>
          </tr>
        </thead>
        <tbody className='body'>
          {searchresults?.length > 0 && <>
            {searchresults?.map((item, i) => (
              <tr key={i} className={1 % 2 == 0 ? 'even' : 'odd'}>
                <td>{item.cname}</td>
                <td><a href={process.env.REACT_APP_CERT_URL+ item.cid + '/'} >{item.cid}</a></td>
                {/* <td>{item.gname}</td> */}
                <td>{item.issued.substring(0, 10)}</td>
              </tr>

            ))}
          </>
          }
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default Certificatesissued;