import React, { useState, useContext, useEffect } from 'react';
import Label from '../../elements/Label/Label';
import Image from '../../elements/image/Image';
import Master from "../../utils/Master";
import axios from "axios";
import './iconslist.css';


const Iconslist = (props) => {
const [errortext,seterrortext]=useState("")
    const {
        iconlist,
        seticonlist,
        groupicons,
        setgroupicons,
        apicalled,
        setapicalled,
        oldupdateiconid,
        setoldupdateiconid,
        newupdateiconpicblob,
        setnewupdateiconpicblob,
        newupdateiconname,
        setnewupdateiconname
    } = useContext(Master);


    function checkchange(value){
        if(props.edit==true){
            if(oldupdateiconid==""){
                setoldupdateiconid(value);
            }else{
                setoldupdateiconid("");
            }
        }

        else{

        var groupiconscopy=[...groupicons];

        if(groupiconscopy.indexOf(value+"") == -1){
            if(groupiconscopy.length<10){
            groupiconscopy.push(value+"");
            }
        }
        else{
            groupiconscopy.splice (groupiconscopy.indexOf(value+""),1); 
        }
        setgroupicons(groupiconscopy);      

    }

    }

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_BASE+"icon")
            .then((response) => {

                seticonlist(response.data.result)
            })
            .catch((error) => {
                console.error('API call error:', error);
            });
    }, [apicalled,])




    return (
        <div className='iconlist'>


            {iconlist?.map((listitem, index) => (
            <>


            { (props.selected) && ((props.groupicons?.indexOf(listitem.id+""))>=0) &&
                <div key={"listitem" + index} className='iconbox'>

                    <div className={"listitemselected"} onClick={()=>checkchange(listitem.id)} > 
                        <Image blob={listitem.img} height={"30px"} width={"30px"} />
                        <Label label={listitem.caption} />
                </div>
                </div>
            }

            { (props.deselected) && ((props.groupicons.indexOf(listitem.id+""))<0) &&
                <div key={"listitem" + index} className='iconbox'>

                    <div className={"listitem"} onClick={()=>checkchange(listitem.id)} > 
                        <Image blob={listitem.img} height={"40px"} width={"40px"} />
                        <Label label={listitem.caption} />
                </div>
                </div>
            }


{ ((props.all) && (listitem.active==1)) &&
                <div key={"listitem" + index} className='iconbox'>
                    <div className={((props.edit==true) && (listitem.id==oldupdateiconid))?"listitemedit":"listitem"} onClick={()=>checkchange(listitem.id)} > 
                        <Image blob={listitem.img} height={"30px"} width={"30px"} />
                        <Label label={listitem.caption} />
                </div>
                </div>
            }

            </>
            
            
            ))}


{errortext.length>0 && 
<div className='errortext'>{errortext} 
</div>

}


        </div>
    );
};

export default Iconslist;