
   const regx={

    numerics:{
      phno:/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
      num:/^[0-9]+$/,
      pincode:/^[0-9]{1,6}$/,
    },

    alphabits:{
      name:/^[a-zA-Z ]+$/,
    },

    alphanumerics:{
      email:/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,


    }


  }


  const buttons={
    ok:{label:'Ok', class:'okbutton'},
    cancel:{label:'Cancel', class:'cancelbutton'},
    update: {label:'Update', class:'updatebutton'},
    add:{label:'Add', class:'addbutton'},
    delete:{label:'Delete', class:'deletebutton'},
    edit:{label:'Edit', class:'editbutton'},
  }



  let libTypeIds = {
    MCQ: 2,
    SAQ: 6,
    LAQ: 7,
    CONTENT: 1,
    MCQ_MULTI: 9,
    ASSIGNMENT: 4,
    MCQ_SINGLE: 8,
    DESCRIPTIVE: 3,
    CODING_CHALLENGE: 5,
  };
  
  let datatypes = {
    NUMERIC: 1,
    ALPHA_NUMERIC: 2,
  };
  
  const PROGRESS_STATUS = {
    LOCKED: 0,
    UNLOCKED: 1,
    EVALUATED: 2,
    REATTEMPT: 3,
    IN_PROGRESS: 6,
    QN_CONTINUE: 7,
    TO_BE_EVALUATED: 4,
    PARTIAL_EVALUATED: 5,
  };
  
  const QUESTION_STATUS = {
    ASSIGNED: 0,
    RESUBMIT: 3,
    CONTINUE: 7,
    SUBMITTED: 2,
    CANCELLED: 4,
    IN_PROGRESS: 6,
    TO_BE_EVALUATED: 5,
  };
  
  const MODE = {
    TEST_MODE: 2,
    PRACTICE_MODE: 1,
  };
  
  const PROCTORING_MODE = {
    TAB: 1,
    NONE: 0,
    TAB_CAM: 2,
  };
  
  const LINK_TYPE = {
    EMBEDED_LINK: 3,
    CONTENT_FILE_LINK: 1,
    ASSIGNMENT_FILE_LINK: 2,
  };
  
  const getLangName = {
    C: "C",
    Cpp: "C++",
    Java: "Java",
    Python: "Python",
    Javascript: "Javascript",
  };
  
  const complexityid = {
    EASY: 1,
    HARD: 3,
    MEDIUM: 2,
  };
  const LANG_ID = {
    C: 3,
    Cpp: 4,
    Java: 1,
    Python: 5,
    Javascript: 2,
  };
  
  const DISPLAY_TEST_RESULT = {
    ALL: 3,
    NONE: 0,
    SCORE: 2,
    SUBMISSION: 1,
  };
  
  const SHOW_LIST_VIEW = {
    NO: 0,
    YES: 1,
  };
  
  const ATTENDANCE_STATUS = {
    ABSENT: 2,
    PRESENT: 1,
    NO_RESPONSE: 0,
    PARTIALLY_PRESENT: 3,
  };
  
  const SESSION_STATUS = {
    ATTENDANCE_INITIATED: 1,
    ATTENDANCE_NOT_RECORDED: 0,
  };
  
  const SESSION_MODE = {
    ONLINE_SESSION: 0,
    OFFLINE_SESSION: 1,
  };
  
  const Roles = {
    hr: "hr",
    admin: "admin",
    learner: "learner",
    trainer: "trainer",
  };
  
  const RESULT_STATUS = {
    SUCCESS: 1000,
    NO_DATA: 2003,
    TIMEOUT: 3000,
    NETWORK_ISSUE: 4000,
    TECHNICAL_ERROR: 2050,
    FAILURE_ATTEMPT: 2000,
    REPLACE_QUESTION: 2001,
    INCORRECT_ANSWER: 2002,
    TESTCASES_FAILED: 2004,
    NO_QNS_AVAILABLE: 3001,
    ALL_TESTCASES_FAILED: 2005,
    PARTIAL_TESTCASES_FAILED: 2006,
  };
  
  const ISNOTESTCASES = {
    TRUE: 1,
    FALSE: 0,
  };

  const Programs = [
    { value: "COE", label: "COE" },
    { value: "Workshop", label: "Workshop" },
    { value: "Bootcamp", label: "Bootcamp" },
    { value: "Internal Trainee", label: "Internal Trainee" },
    {value:"Webinar", label: "Webinar"}
];

  
  module.exports = {
    MODE,
    Roles,
    LANG_ID,
    LINK_TYPE,
    datatypes,
    libTypeIds,
    getLangName,
    complexityid,
    SESSION_MODE,
    ISNOTESTCASES,
    RESULT_STATUS,
    SHOW_LIST_VIEW,
    SESSION_STATUS,
    PROGRESS_STATUS,
    QUESTION_STATUS,
    PROCTORING_MODE,
    ATTENDANCE_STATUS,
    DISPLAY_TEST_RESULT,
    regx,
    buttons,
    Programs
  };
  