import React, { useState, useContext } from 'react';
import './iconupload.css';
import Iconslist from '../Iconslist/Iconslist';
import Imgupload from '../imgupload/Imgupload';
import Notifications, {notify} from 'react-notify-toast';



const Iconupload = (props) => {


  return (

    <div className='iconupload'>
      <Imgupload/>
      <Iconslist  showselected={false} showdeselected={false} all={true} edit={true} />

    </div>
  );
};

export default Iconupload;