
import { createContext, useRef, useState } from "react";
const Master = createContext({});

export const MasterContext = ({ children }) => {
  const [picblob,setpicblob]=useState("");
  const [iconname,seticonname]=useState("");
  const [iconlist,seticonlist]=useState([]);
  const [groupname,setgroupname]=useState("");
  const [groupdesc,setgroupdesc]=useState("");
  const [groupicons,setgroupicons]=useState([]);
  const [groupadd,setgroupadd]=useState(false);
  const [groupmode,setgroupmode]=useState(false);
  const [grouplist,setgrouplist]=useState([]);
  const [groupviewmode,setgroupviewmode]=useState(false);
  const [csvfile,setcsvfile]=useState();
  const [selectedgroupid,setselectedgroupid]=useState();
  const [selectedgroupobject,setselectedgroupobject]=useState();
  const [program,setprogram]=useState("");
  const [candidatename,setcandidatename]=useState("");
  const [certificateid,setcertificateid]=useState("");
  const [issuedate,setissuedate]=useState();
  const [certificateicons,secertificateicons]=useState("");
  const [selectedmenu,setselectedmenu]=useState("Topics");
  const [displayname,setdisplayname]=useState("");
  const [certificatetitle,setcertificatetitle]=useState("");
  const [certificatesubtitle,setcertificatesubtitle]=useState("");
  const [certificatedesc,setcertificatedesc]=useState("");
  const [showstudentdata,setshowstudentdata]=useState(false);
  const [csvfiledata,setcsvfiledata]=useState();
  const [csvfiledatainlines,setcsvfiledatainlines]=useState([]);
  const [csvfilename,setcsvfilename]=useState([]);
  const [csvfiledatainarray,setcsvfiledatainarray]=useState([]);
  const [saveandcontinue,setsaveandcontinue]=useState(false);
  const [searchterm,setsearchterm]=useState("");
  const [searchresults,setsearchresults]=useState([]);
  const [apicalled,setapicalled]=useState(false);
  const [errors,seterrors]=useState({});
  const [showcsvdata,setshowcsvdata]=useState(false);
  const [desccoursename,setdesccoursename]=useState("");
  const [emailhtml,setemailhtml]=useState("");
  const [certificateiconsshow,setcertificateiconsshow]=useState(true);
  const [token,settoken]=useState("");
  const [refreshToken,setrefreshToken]=useState("");
  const [newupdateiconname,setnewupdateiconname]=useState("");
  const [newupdateiconpicblob,setnewupdateiconpicblob]=useState("");
  const [oldupdateiconid,setoldupdateiconid]=useState("");
  const [certificatepostsubtitle,setcertificatepostsubtitle]=useState("");
  const [aboutcourse,setaboutcourse]=useState("");
  

  function buildstudentdata(lines){
    var temp=[];

    for (var i=1;i<lines.length-1;i++){
      var entry=[]
      var candi=lines[i].split(',');
if(candi.length>2)
  {
      entry.push(removeunwanted(candi[0],false,true));
      entry.push(removeunwanted(candi[1],true,true));
      entry.push(removeunwanted(candi[2],true,true));
      // entry.push(removeunwanted(candi[3],true,true));
      temp.push(entry);
  }
    }
  return temp;
  
  }
  

function removeunwanted(text,spaces,endchar){
if(spaces){
    text=text.replace(" ","");
}
if(endchar){
    text=text.replace("\r","");
    text=text.replace("\n","");
}
return text;

}

function getrole(){

  if (localStorage.getItem("username") === null) {
    return null;
  }
  var role = localStorage.getItem("role");
  return role

}

function clear(){
  setpicblob("");
  seticonname("");
  seticonlist([]);
  setgroupname("");
  setgroupdesc("");
  setgroupicons("");
  setgroupadd(false);
  setgroupmode(false);
  setgrouplist([]);
  setgroupviewmode(false);
  setgrouplist([]);
  setgroupviewmode([]);
  setcsvfile();
  setselectedgroupid();
  setselectedgroupobject();
  setprogram("");
  setcandidatename('');
  setcertificateid('');
  setissuedate('');
  secertificateicons("");
  setselectedmenu('Topics');
  setdisplayname("");
  setcertificatetitle("");
  setcertificatesubtitle("");
  setcertificatedesc("");
  setshowstudentdata(false);
  setcsvfiledata();
  setcsvfiledatainlines([]);
  setcsvfilename([]);
  setcsvfiledatainarray([]);
  setsaveandcontinue([]);
  setsearchterm('');
  setsearchresults([]);
  setapicalled(false);
  seterrors({});
  setshowcsvdata(false);
  setdesccoursename("");
  setemailhtml("");
  setcertificateiconsshow(true);
  settoken("");
  setrefreshToken("");
  setnewupdateiconname("");
  setnewupdateiconpicblob("");
  setoldupdateiconid("");
  setcertificatepostsubtitle("");
  setaboutcourse("");

}


  return (
    <Master.Provider
      value={{
        picblob,
        setpicblob,
        iconname,
        seticonname,
        iconlist,
        seticonlist,
        groupname,
        setgroupname,
        groupicons,
        setgroupicons,
        groupadd,
        setgroupadd,
        groupmode,
        setgroupmode,
        grouplist,
        setgrouplist,
        setgroupviewmode,
        groupviewmode,
        csvfile,
        setcsvfile,
        selectedgroupid,
        setselectedgroupid,
        setgroupdesc,
        groupdesc,
        program,
        setprogram,
        candidatename,
        setcandidatename,
        issuedate,
        setissuedate,
        certificateid,
        setcertificateid,
        certificateicons,
        secertificateicons,
        selectedmenu,
        setselectedmenu,
        displayname,
        setdisplayname,
        certificatetitle,
        setcertificatetitle,
        certificatedesc,
        setcertificatedesc,
        showstudentdata,
        setshowstudentdata,
        buildstudentdata,
        csvfiledata,
        setcsvfiledata,
        csvfiledatainlines,
        setcsvfiledatainlines,
        csvfilename,
        setcsvfilename,
        csvfiledatainarray,
        setcsvfiledatainarray,
        selectedgroupobject,
        setselectedgroupobject,
        certificatesubtitle,
        setcertificatesubtitle,
        saveandcontinue,
        setsaveandcontinue,
        searchresults,
        setsearchresults,
        searchterm,
        setsearchterm,
        apicalled,
        setapicalled,
        showcsvdata,
        setshowcsvdata,
        desccoursename,
        setdesccoursename,
        emailhtml,
        setemailhtml,
        certificateiconsshow,
        setcertificateiconsshow,
        getrole,
        refreshToken,
        setrefreshToken,
        token,
        settoken,
        oldupdateiconid,
        setoldupdateiconid,
        newupdateiconpicblob,
        setnewupdateiconpicblob,
        newupdateiconname,
        setnewupdateiconname,
        certificatepostsubtitle,
        setcertificatepostsubtitle,
        aboutcourse,
        setaboutcourse,
        clear
      }}
    >
      {children}
    </Master.Provider>
  );
};


export default Master;