import React, { useState, useContext, useEffect } from 'react';
import Notifications, {notify} from 'react-notify-toast';
import Text from './../../elements/text/Text';
import Image from './../../elements/image/Image';
import Master from "./../../utils/Master";
import axios from "axios";
import Button from './../../elements/button/Button';
import * as constants from '../../constants/Constants';
import './Imgupload.css';



const Imgupload = (props) => {

    const {
        picblob,
        setpicblob,
        iconname,
        seticonname,
        apicalled,
        setapicalled,
        oldupdateiconid,
        setoldupdateiconid,
        newupdateiconpicblob,
        setnewupdateiconpicblob,
        newupdateiconname,
        setnewupdateiconname,
        clear
    } = useContext(Master);

    useEffect(()=>{

        setoldupdateiconid("");
        setnewupdateiconpicblob("");
        seticonname("");

    },[])



    const [uploaderror, setuploaderror] = useState();

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    async function imgconv(file) {
        convertToBase64(file).then((res) => { 
            
           if( oldupdateiconid=="")
            {
                
            setpicblob(res) 
            }else{
                setnewupdateiconpicblob(res)
            }
        
        
        });
    }

    async function saveicon() {
        console.log("iconname", iconname.length);
        console.log("picblob", picblob.length);

        if ((picblob == null) || (picblob == undefined) || (iconname == null) || (iconname == undefined)||
         ((picblob.length==0)  && (newupdateiconpicblob.length==0)) || (iconname.length==0)) {
            let myColor = { background: '#FF0000', text: "#FFFFFF" };
            
            if(iconname.length==0){
                notify.show('Enter a name for the Icon!', 'error', 5000, myColor);
            }


            if((picblob.length==0)  && (newupdateiconpicblob.length==0)){
                notify.show('Please Upload Icons!', 'error', 5000, myColor);
            }
        } else {
            axios
                .post(process.env.REACT_APP_API_BASE+'icon',
                    JSON.stringify({
                        iconname: iconname,
                        picblob:oldupdateiconid==""? picblob:newupdateiconpicblob,
                        type:oldupdateiconid==""? "add":"edit",
                        oldid:oldupdateiconid==""?0:oldupdateiconid
                    }),
                    {
                        headers: {
                            "Content-type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                    }
                    let myColor = { background: '#0E1717', text: "#FFFFFF" };
                    notify.show('Icon Uploaded!', 'success', 5000, myColor);
                    setpicblob("");
                    seticonname("");
                    setapicalled(!apicalled);
                    setnewupdateiconpicblob("");
                    setoldupdateiconid("")
                })
                .catch((error) => {
                    console.error('API call error:', error);
                });
        }
    }


    return (

        <div className='imgupload'>
            <Notifications />
                <div className='name'>
                    <Text
                        label={"Icon Name"}
                        update={seticonname}
                        value={iconname}
                        required={true}
                        name={"iconame"}
                        regx={constants.regx.alphabits.name}
                        errortext={"Enter only alphabits"}
                    />
                </div>

                <div className='upload-section'>

                    <div className='file-button'>
                        <input id="avatar" type="file" name="avatar" onChange={async (event) => imgconv(event.target.files[0])} />
                    </div>
                    
                  
                    {oldupdateiconid=="" &&
                    <div className='save-button'>
                        <Button onclick={saveicon} label={"upload logo"} classname={"addbutton"} />
                    </div>
}

{oldupdateiconid>0 &&
                    <div className='edit-button'>
                        <Button onclick={saveicon} label={"Update logo"} classname={"editbutton"} />
                    </div>
}
                </div>

                <div className='iconimage'>

                {(picblob.length>0 )&&
                        <div className='icon-image'>
                            <Image blob={picblob} height={"20%"} width={"20%"} />
                        </div>
                    }

{(newupdateiconpicblob.length > 0 )&&
                        <div className='icon-image'>
                            <Image blob={newupdateiconpicblob} height={"20%"} width={"20%"} />
                        </div>
                    }


                </div>

        </div>
    );
};

export default Imgupload;