import React, { useEffect, useState } from 'react';
import './check.css';

const Check = (props) => {
  useEffect(()=>{


  },[props.checked])

    return (
      
      <div className={'icheck'}>                
        <input type="checkbox" 
        className={'icheckbox'}
        checked={props.checked} 
        name={props.name} 
        value={props.value} 
        onChange={(e)=>props.onchange(e.target.value)} 
        />
        {/* <span className={"checklabel"}>{props.label}</span> */}
      </div>
    );
  };

  export default Check;