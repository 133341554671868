import React, { useState, useContext, useEffect } from 'react';
import Image from '../../elements/image/Image';
import Master from "../../utils/Master";
import axios from "axios";
import './cicons.css';



const Cicons = (props) => {

    const {
        iconlist,
        seticonlist,
        groupicons,
        setgroupicons,
        certificateicons,
    } = useContext(Master);

    function checkchange(value){
        var groupiconscopy=[...groupicons];
        if(groupiconscopy.indexOf(value+"") == -1){
            groupiconscopy.push(value+"");

        }else{
            groupiconscopy.splice (groupiconscopy.indexOf(value+""),1); 
        }
        setgroupicons(groupiconscopy);        
    }

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_BASE+`icon`)
            .then((response) => {

                seticonlist(response.data.result);
            })
            .catch((error) => {
                console.error('API call error:', error);
            });
    }, [])



    return (
                 <div className={props.class}>
            {iconlist.map((listitem, index) => (
                   <>
                { (certificateicons.split(',').indexOf(listitem.id+"")>=0) && 
                        <span key={"icons"+index} className='onlyicons'>
                        <Image blob={listitem.img} />
                        </span>         
            }
                      </>
    ))
    }
    </div> 
    );
}
export default Cicons;