import React, { useState, useContext, useEffect } from 'react';
import './Studentupload.css';
import Master from "../../utils/Master";
import Fileupload from '../../elements/fileupload/Fileupload';
import Button from '../../elements/button/Button';
import Label from '../../elements/Label/Label';



const Studentupload = (props) => {


    const {
        csvfiledata,
        setcsvfiledata,
        csvfiledatainlines,
        setcsvfiledatainlines,
        csvfilename,
        setcsvfilename,
        buildstudentdata,
        csvfiledatainarray,
        setcsvfiledatainarray,
        setshowcsvdata
    } = useContext(Master);

const [errortext,seterrortext]=useState("");

function makestudentdata(){
    setcsvfiledatainarray(buildstudentdata(csvfiledatainlines))
    setshowcsvdata(true);

    setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, 500);
}

useEffect(()=>{
    setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, 500);
},[setcsvfilename])


    return (
 <div className='studentuploaddiv'>
    <Fileupload label={"Upload Student"}  setfilename={setcsvfilename} setfiledatalines={setcsvfiledatainlines} setfiledata={setcsvfiledata}/>
    
        <Label label={csvfilename}/>
        {csvfilename.length>0 &&
<Button onclick={()=>makestudentdata()} classname={"csvuploadvutton"} disabled={false} label={"Upload"}/>
}

        </div>


    );
};

export default Studentupload;