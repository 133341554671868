import React, { useState } from 'react';
import './label.css';



const Label = (props) => {
    let [haserror, sethaserror] = useState(false);
    let [inedit, setinedit] = useState(false);
    let [ipboxclass,setipboxclass]=useState("ipbox");



    return (
        <>
        
            <div className={"ispan"}>
                    <span className="label">{props.label}</span>
                </div>

        </>
    )

}

export default Label;
