import React, { useState } from 'react';
import './image.css';

const Image = (props) => {

    return (
      <div className='iimage'>
        
    <img width={props.width} height={props.height} src={ props.blob}/>
      </div>
    );
  };

  export default Image;