import React, { useState,useContext, useEffect } from 'react';
import RichTextEditor from 'react-rte';
import Master from "../../utils/Master";


const Rtecourse = (props) => {

    const {
        setaboutcourse,
        aboutcourse,
    } = useContext(Master);


    const [value, setValue] = useState(RichTextEditor.createEmptyValue());

    const onChange = (value) => {
      setValue(value);

      setaboutcourse(value.toString('html'));

      if (props.onChange) {
        props.onChange(value.toString('html'));
      }
    };

  
    return <RichTextEditor value={value} onChange={onChange} />;
  };
export default Rtecourse;