import React, { useState } from 'react';
import './textareagen.css';



const Textareagen = (props) => {
    let [haserror, sethaserror] = useState(false);
    let [inedit, setinedit] = useState(false);
    // let [iptextareaboxclass,setiptextareaboxclass]=useState("iptextareabox");

    // function handlefocus(e) {
    //     setiptextareaboxclass('iptextareaboxedit');
    // }
    // function handlefocusout(e) {
    //     validation(e,'focusout');
    // }

    function handlechange(e) {
        props.update(e)
        // if(props.regx!=undefined || props.regex!=null){
        //     validation(e,'change');
        // }


    }

    // function validation(inputtxt,from) {
    //         var regx = props.regx;
    //         if(inputtxt==""){
    //             setiptextareaboxclass('iptextareabox')
    //         }
    //         else{
    //         if(inputtxt.match(regx)) {
    //             if(from=='focusout'){
                    
    //                 setiptextareaboxclass  ('iptextareaboxok')
    //             }
    //             if(from=='change'){
    //                 setiptextareaboxclass('iptextareaboxedit')
    //             }


    //         }  
    //         else {  
    //         setiptextareaboxclass('iptextareaboxerror')
    //         }
    //     }
    // }


    return (
        <>
            {/* <div className={"itextarea"}> */}
                    <textarea
                        // className={iptextareaboxclass}
                        // onFocus={(e)=>handlefocus(e)}
                        // onBlur={(e)=>handlefocusout(e.target.value)}
                        value={props.value}
                        required={props.required}
                        name={props.name}
                        onChange={(e)=>handlechange(e.target.value)}
                        // onSelect={(e)=>handlefocus(e)}
                        // rows="2"
                        // cols="100%"
                        placeholder={props.placeholder}
                    />

{/* </div> */}

        </>
    )

}

export default Textareagen;
