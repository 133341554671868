import "./LoginPage.css";
import React, { useContext, useEffect, useRef } from "react";
import {useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Master from "./../../utils/Master";
import axios from "axios";
import constants from "../../constants/Constants.js";


const LoginPage = () => {

  const {
    // refreshToken,
    setrefreshToken,
    // token,
    settoken
} = useContext(Master);

const { default: jwt_decode } = require("jwt-decode");

  const navigate = useNavigate();

  let location = useLocation();

  const from = useRef(location?.state?.from?.pathname || "/");

  useEffect(() => {

    setTimeout(() => {
      


      /* global google*/

      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("signInBtn"),
        { theme: "outline", size: "large" } // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog

      async function handleCredentialResponse(response) {
        // setIsLoaded(true);
        // try {
          const validateResponse = await axios.post(process.env.REACT_APP_NODE_API+
            "node/auth/validate",
            { jwtcredential: response.credential, refreshToken:localStorage.getItem('refreshToken') },
            {
              headers: {          
                "Content-type": "application/json",
                Authorization: "abdjcdifouvn",
              },
              // withCredentials: true,
            }
          );

          let token = validateResponse.data.accessToken;
          let refreshToken = validateResponse.data.refreshToken;
          setrefreshToken(validateResponse.data.refreshToken);
          settoken(validateResponse.data.accessToken);


            let result = jwtDecode(token);
            if(result.role==process.env.REACT_APP_ROLE){

              localStorage.setItem(process.env.REACT_APP_ROLE_NAME, process.env.REACT_APP_ROLE);
              navigate('/icongroups');


            }
    } 
    

  }, 1000);
  }
  
  
  , []);

  return (
    <>
      <div className="loginpg"></div>
      <form className="loginForm">
        <div className="image">
          <img
            alt="Gradious"
            className="logo1"
            style={{ width: "200px" }}
            src="https://gradious.com/wp-content/uploads/2021/09/Final-Logo-2.svg"
          />
        </div>
        <div className="tabs">Sign In</div>
        <div className="signInForm">
          <button id="signInBtn"> SignIn with google </button>
        </div>
      </form>
    </>
  );
};

export default LoginPage;