// Filename - App.js

import React, { useState, useEffect } from "react";
import { BlockPicker } from "react-color";
import { Routes, Route, useNavigate, useLocation,BrowserRouter,Link } from 'react-router-dom';
import Iconupload from './components/iconupload/Iconupload.js'
import IconGroup from "./components/IconGroups/IconGroup.js";
// Import scss file
import "./App.scss";
import Grouplist from "./components/grouplist/Grouplist.js";
import Certificate from "./components/certificate/Certificate.js";
import Header from "./components/header/Header.js";
import Createandgen from "./components/createandgen/Createandgen.js";
import Certificatesissued from "./components/certificatesissued/Certificatesissued.js";
import Csvdata from "./components/csvdata/Csvdata.js";
import LoginPage from "./components/LoginPage/LoginPage.js";


export default function App() {

    const navigate = useNavigate();

	useEffect(() => {
		if((window.location.href.includes('certificate') || window.location.href.includes('Certificate')) ==false){
			if(localStorage.getItem(process.env.REACT_APP_ROLE_NAME) === null)
			{
				navigate('/');
	
			}
	
				if(localStorage.getItem(process.env.REACT_APP_ROLE_NAME) === process.env.REACT_APP_ROLE)
			{
				// navigate('/icongroups');
	
			}

		}


		return () => { 
			
			if(localStorage.getItem(process.env.REACT_APP_ROLE_NAME)!=null){
			localStorage.removeItem(process.env.REACT_APP_ROLE_NAME);
			}

		 };

		
	}, []);


	return (
		<div className="App">
			 {/* {(((window.location.href.includes('certificate') || window.location.href.includes('Certificate') )==false) && (localStorage.getItem(process.env.REACT_APP_ROLE_NAME) == process.env.REACT_APP_ROLE) ) &&
			<Header/>
	}  */}
			<Routes>
				{localStorage.getItem(process.env.REACT_APP_ROLE_NAME)==process.env.REACT_APP_ROLE && <>
			<Route  path='/groups' element={<><Header/><Grouplist/></>}/>
			  <Route  path='/addicons' element={<><Header/><Iconupload/></>}/>
			  <Route  path='/icongroups' element={<><Header/><Grouplist/></>}/>
			  <Route  path='/addicongroups' element={<><Header/><IconGroup/></>}/>
			  <Route  path='/issued' element={<><Header/><Certificatesissued/></>}/>
			  <Route  path='/new' element={<><Header/><Createandgen/></>}/>
			  </>
			 } 
			  <Route  path='/certificate/*' element={<Certificate/>}/>
			 <Route  path='/' element={<LoginPage/>}/> 
			</Routes>
		  
		</div>
	  );
}
