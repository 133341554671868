import React, { useState, useContext, useEffect } from 'react';
import Master from "../../utils/Master";
import axios from "axios";
import './grouplist.css';
import Iconslist from '../Iconslist/Iconslist';



const Grouplist = (props) => {

    const {
        grouplist,
        setgrouplist,
        setselectedgroupid,
        apicalled,
        setapicalled
    } = useContext(Master);


    useEffect(() => {

        axios
            .get(process.env.REACT_APP_API_BASE+`group`)
            .then((response) => {
                setgrouplist(response.data.result)
            })
            .catch((error) => {
                console.error('API call error:', error);
            });

    }, [])



const handleUploadUserfile = (event, file, id, program) => {
    setselectedgroupid(id);
    if (!file.size) return;


    const form = new FormData();

    form.append("csv", file);
    form.append("groupid", id);
    form.append("program", program);

    axios
        .post(process.env.REACT_APP_API_BASE+"generate", form, {
            mode: "no-cors",
            headers: {
                "Content-type": "multipart/form-data",
            },
        })
        .then((response) => {
            setapicalled(!apicalled)

        })
        .catch((error) => {
        })
        .finally(() => {
        }
        );
};



    return (

        <div className='grouplist'>
            <div>
            <h5>Icon Group List</h5>
            </div>
            <div>
            <table className='tablemain'>
    <thead className='headsection'>
          <tr className='head'>
            <th>Name</th>
            <th>Icons</th>
          </tr>
          </thead>
          <tbody className='body'>
          {grouplist.map((item, i) => (
            <tr key={i} className={1%2==0?'even':'odd'}> 
              <td>{item.name}</td>
              <td><Iconslist groupicons={item?.iconlist?.split(',')} selected={true}/></td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
        </div>
    );
};

export default Grouplist;